import { render, staticRenderFns } from "./PackingToggle.vue?vue&type=template&id=097ef5c4&scoped=true&"
import script from "./PackingToggle.vue?vue&type=script&lang=js&"
export * from "./PackingToggle.vue?vue&type=script&lang=js&"
import style1 from "./PackingToggle.vue?vue&type=style&index=1&id=097ef5c4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "097ef5c4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VIcon,VImg,VProgressCircular,VSpacer,VSwitch})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('097ef5c4')) {
      api.createRecord('097ef5c4', component.options)
    } else {
      api.reload('097ef5c4', component.options)
    }
    module.hot.accept("./PackingToggle.vue?vue&type=template&id=097ef5c4&scoped=true&", function () {
      api.rerender('097ef5c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ShopSetting/Pages/Packing/components/PackingToggle.vue"
export default component.exports